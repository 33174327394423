import React from 'react';
import cn from 'classnames';
import IconUnlock from '@dealroadshow/uikit/core/components/Icon/IconUnlock';
import URL from '@/finsight/Router/urlConstants';
import { Link } from '@/Framework/Router/Next/Link';

import styles from './styles.scss';

const UpgradePlanButton = ({ className = '', dataTest = '' }) => (
  <Link
    dataTest={ dataTest || 'upgradePlanButton' }
    className={ cn(styles.button, className) }
    to={ URL.PRICING }
  >
    Upgrade Plan
    <IconUnlock
      className={ styles.icon }
    />
  </Link>
);

export default UpgradePlanButton;
