import React from 'react';
import IconInfo from '@dealroadshow/uikit/core/components/Icon/IconInfo';

import styles from './styles.scss';

const DesktopOnlyCaption = () => (
  <div className={ styles.desktopOnlyCaption }>
    <IconInfo />
    Currently available on Desktop
  </div>
);

export default DesktopOnlyCaption;
