import React from 'react';
import IconCheck from '@dealroadshow/uikit/core/components/Icon/IconCheck';
import styles from './styles.scss';

interface IProps {
  text: string,
}

const FeatureItem = ({ text }: IProps) => (
  <div className={ styles.featureItem }>
    <IconCheck
      className={ styles.icon }
    />
    <div className={ styles.text }>{ text }</div>
  </div>
);

export default FeatureItem;
